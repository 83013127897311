<template>
<div class="library">
<div class="row">
  <div class="col-md-6">
      <ul class="nav">
      <li>
      <a href  ="javascript:;" @click.prevent="createFolder">Create Folder</a>
      </li><li>
       <a href  ="javascript:;" @click="getFiles">Refresh</a>
      </li>
      </ul>
      <FileBreadcrumb :parents="parents" @fetchFolderFiles="fetchFolderFiles"/>
    </div>
  <div class="col-md-6">
    <div class="row float-right search-form">
      <div class="col-md-6">
        <input type="search" v-model="search" class="form-control" placeholder="Filter Files" />
      </div>
      <div class="col-md-6">
        <button class="btn btn-primary" @click="upload()">Upload </button>
      </div>
    </div>
  </div>
  </div>

  <FileViewer :loading="loading" :files="filteredFiles" @fileDeleted="fileDeleted" @fetchFolderFiles="fetchFolderFiles" @fileSelected="fileSelected">
  </FileViewer>
   <b-modal id="modal-upload" size="xl"  centered no-close-on-backdrop lazy hide-footer>
   <template v-slot:modal-title>
    <h5> Upload Files <span v-if="folderName">to {{folderName}}</span></h5>
   </template>
 <FileUpload :folderId="folderId" :folderName="folderName"  @fileAdded="fileAdded" /></b-modal>
 <b-modal id="folder-create"  :title="folderName && folderId? `Create Folder under ${folderName}`: `Create Folder` " centered no-close-on-backdrop lazy  hide-footer>
    <FolderCreate :folderId="folderId" :folderName="folderName" @fileAdded="fileAdded"/></b-modal>
  </div>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';
import FileViewer from './FileViewer.vue';
import FileUpload from './FileUpload.vue';
import FolderCreate from './FolderCreate.vue';
import FileBreadcrumb from './FileBreadcrumb.vue';

export default {
  name: 'FileLibrary',
  components: {
    FileViewer, FileUpload, FolderCreate, FileBreadcrumb,
  },
  data() {
    return {
      folderId: this.$route.params.id,
      folderName: this.$route.params.name,
      search: '',
      parents: [],
      loading: false,
    };
  },
  computed: {
    ...mapState('file', {
      files: 'files',
    }),
    ...mapGetters('file', {}),
    filteredFiles() {
      return this.files.filter(file => file.name.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  methods: {
    getFiles() {
      this.loading = true;
      this.$store.dispatch('file/getFiles', this.folderId).then(() => {
        this.loading = false;
      });
    },
    fetchFolderFiles(parentId, parentName) {
      this.folderId = parentId;
      this.folderName = parentName;
      this.getFiles();
      this.handleBreadcrumb(parentId, parentName);
    },
    handleBreadcrumb(id, name) {
      const parent = {
        id,
        name,
      };
      const eParent = this.parents.find(x => x.id === id);
      if (eParent) {
        const index = this.parents.indexOf(eParent);
        const { length } = this.parents;
        this.parents.splice(index + 1, length - index);
        return;
      }
      this.parents.push(parent);
    },
    createFolder() {
      this.$bvModal.show('folder-create');
    },
    upload() {
      this.$bvModal.show('modal-upload');
    },
    goHome() {
      this.$router.push({ name: 'drive' });
      this.parents = [{ id: null, name: 'Home' }];
    },
    fileDeleted() {
      this.getFiles();
    },
    fileAdded() {
      this.getFiles();
    },
    fileSelected($event) {
      this.$emit('onFileSelected', $event);
    },
  },
  mounted() {
    this.parents = [{ id: null, name: 'Home' }];
    this.getFiles();
  },
  beforeDestroy() {
    this.$off(['fileAdded', 'fileDeleted', 'fileSelected']);
  },
};
</script>
<style lang="stylus">
:root
  --library_bg_color: #fff

.library
  background: var(--library_bg_color);
  padding: 1rem;
  .nav
    li
     display: inline-block;
     margin-right: .5rem;
  .search-form
    margin-bottom: 2rem

</style>
