<template>
  <div class="row">
   <div class="col-md-12 d-flex justify-content-center mb-3 text-success">
  <b-spinner variant="primary" label="Loading..." v-if="loading"></b-spinner>
  </div>
   <div :class="{'col-md-9':showPreview, 'col-md-12':!showPreview}">
    <div class="row">
      <div class="col-md-3" v-for="f in files" :key="f.id">
     <div class="card">
                    <div class="file">
                        <a href="javascript:;"  @click.prevent="preview(f)">
                            <div class="hover">
                                <a  v-if="!isFolder(f.mimeType)" :href="f.properties.url" target="_blank" class="btn btn-danger">
                                    <EyeIcon />
                                </a>
                                 <button type="button" id="delete-icon" class="btn btn-danger" @click.prevent="deleteFile(f.id)">
                                    <DeleteIcon />
                                </button>
                            </div>
                            <div class="icon">
                            <template v-if="!isFolder(f.mimeType)">
                                <img :src="f.thumbnailLink" :alt="f.name+' thumbnail'" class="thumbnail" crossorigin="anonymous">
                            </template>
                            <template v-else>
                                <img :src="f.iconLink" :alt="f.name+' icon'" crossorigin="anonymous">
                            </template>
                            </div>
                            <div class="file-name">
                                <p class="m-b-5 text-muted">{{f.name}}</p>
                            </div>
                        </a>
                    </div>
                </div>
      </div>
    </div>
  </div>
  <div class="col-md-3 preview" v-if="showPreview && seletedFiles.length">
                    <b-btn-close @click="cancelPreview"></b-btn-close>
   <div class="card">
                    <div class="card-body">
                        <ul>
                        <li v-for="(f, index) of seletedFiles" :key="index"> {{f.name}}</li>
                        </ul>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-success" @click.prevent="insert">Insert ({{seletedFiles.length}}) Files</button>
                      <button class="btn btn-danger" @click.prevent="cancelPreview">X</button>
                    </div>
                 </div>
     </div>


  </div>


</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DeleteIcon from 'mdi-vue/Delete';
import EyeIcon from 'mdi-vue/Eye';
import { isImage } from '@/util/util';

export default {
  name: 'FileLibrary',
  components: { DeleteIcon, EyeIcon },
  props: ['files', 'loading'],
  data() {
    return {
      showPreview: false,
      file: null,
      seletedFiles: [],
    };
  },

  methods: {
    ...mapMutations('file', {}),
    ...mapActions('file', {
      getFiles: 'getFiles',
    }),
    isImage(mimeType) {
      if (!mimeType) { return false; }
      return isImage(mimeType);
    },
    isFolder(mimeType) {
      if (!mimeType) { return false; }
      return mimeType === 'application/vnd.google-apps.folder';
    },
    preview(file) {
      if (!file) {
        return;
      }
      if (this.isFolder(file.mimeType)) {
        this.$emit('fetchFolderFiles', file.id, file.name);
        // this.$router.push({ name: 'folder', params: { id: file.id, name: file.name } });
        this.cancelPreview();
      } else {
        this.showPreview = true;
        this.seletedFiles.push(file);
      }
    },
    insert() {
      this.$emit('fileSelected', this.seletedFiles);
    },
    cancelPreview() {
      this.file = null;
      this.seletedFiles = [];
      this.showPreview = false;
    },
    deleteFile(fileId) {
      if (fileId) {
        this.$store.dispatch('file/deleteFile', fileId).then(() => {
          this.$emit('fileDeleted');
          this.cancelPreview();
        });
      }
    },
  },
  mounted() {
  },
};
</script>
<style lang="stylus" >
:root
  --library_bg_color: #fff
  --drag_area_border_color: #eee
  --box_shadow: 0 0 1.5rem rgba(0,0,0,0.1), 0 3rem 10rem rgba(0,0,0,0.2)
  --trans: all 0.2s ease-in-out
  --file_name_boder_color: #eceff1
.library
  padding: 1rem;
  .card
    background: var(--library_bg_color);
    transition: .5s;
    border: 0;
    border-radius: .1875rem;
    position: relative;
    width: 100%;
    box-shadow: none;
    margin-bottom: 1rem;
    .file
      padding: 0 !important;
      position:relative;
      &:hover
        box-shadow: var(--box_shadow)
        transition: var(--trans)
        .hover
          display:block
      a
       text-decoration:none
       z-index 1
       .hover
          position: absolute;
          z-index 2
          right: 1rem;
          top: 1rem;
          display: none;
          transition: all 0.2s ease-in-out;
          #delete-icon
           cursor default
          button, a
            padding: 0;
            font-size: .9375rem;
            overflow: hidden;
            line-height: normal;
            margin-left: 0.2rem
        .icon
          padding: 1rem .5rem;
          display: table;
          width: 100%;
          text-align: center;
          height: 7rem;
          overflow: hidden;
          background-size: cover;
          background-position: top;
          img
           padding:2rem 0
           &.thumbnail
            padding: 2rem 0 !important;
            width: 2rem;
            height: 7rem;
        .file-name
          padding: 1rem;
          border-top: 1px solid var(--file_name_boder_color);

.preview
  .card
    .file
    &:hover
      box-shadow: var(--box_shadow)
      transition: var(--trans)
    .icon
      img
      &.thumbnail
        padding:0 !important
        width:100%
        height:100%


</style>
