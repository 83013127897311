<template>
<ul class="breadcrumb" v-if="parents.length > 1">
      <li v-for="p in parents" :key="p.id">
        <a href="javascript:;" @click="fetchFolderFiles(p)">{{p.name}}</a>
      </li>
       </ul>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: ['parents'],
  data() {
    return {
    };
  },
  methods: {
    fetchFolderFiles(parent) {
      if (!parent) {
        this.$emit('fetchFolderFiles');
        return;
      }
      this.$emit('fetchFolderFiles', parent.id, parent.name);
    },

  },
};
</script>
<style lang="stylus" scoped>
.breadcrumb
  padding 0
  background-color transparent
  margin-bottom 0
  li
   &:after
    content '>>'
    font-size 14px
   &:last-child:after
    content ''
   a
    padding 0 .2rem
    font-size .8rem


</style>
